import React, { Component } from "react";
import BannerArea from "../../components/banner/BannerArea";

class About extends Component {
  render() {
    const theme = this.props.theme;
    return (
      <div>
        <BannerArea theme={this.props.theme} page={"About Us"}/>
        {/* <AboutHeader theme={this.props.theme} /> */}
            {/* <div id="banner-area" class="banner-area" style={{backgroundImage: `url(images/banner/banner1.jpg)` }}>
                <div class="banner-text">
                    <div class="container">
                        <div class="row">
                        <div class="col-lg-12">
                            <div class="banner-heading">
                                <h1 class="banner-title">About</h1>
                                <nav aria-label="breadcrumb">
                                    <ol class="breadcrumb justify-content-center">
                                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                                    <li class="breadcrumb-item"><a href="#">company</a></li>
                                    <li class="breadcrumb-item active" aria-current="page">About Us</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div> */}
            
            <div id="main-container" class="main-container">
                <div class="container">
                    {/* <div class="row mt-5">
                        <div class="col-lg-12">
                            <h3 class="column-title">A word from the founder</h3>
                            <blockquote><p>As Managing Director and CEO of VAINQUEUR AGRO SARL, I am delighted to present you, our passion, our team, our tropical product and our various services.</p></blockquote>
                            <p>Almost 12 years ago, I started this journey as a commodity broker, I got the passionfrom my favorite Uncle, and my Late Father. Years later, I become a Registred Exporter, and I have been a source of inspiration to other youths around me. I have served in the industry with dedicated collaborators, with whom I have managed to build a solid reputation in the procurement and exportation of agro commodities. 
                                Our combined experiences in the field of agricultural commodities allow us to respond quickly and efficiently to the needs of our customers from all round the world.
                            </p>
                            <p>We are maintaining good business ethics which is important for the relationships we are building and consequently for our reputation.</p>
                            <p><span style={{fontWeight: 'bold'}}> VAINQUEUR AGRO SARL</span> and its staffs are commited every day to offer you good quality commodity and high-quality service, as we are concernedabout creating mor values for your satisfaction. We look forward to a fruitful partnership.</p>
                            <div class="text-right" style={{fontWeight: 'bold', color: 'red'}}>Mark O. ADEGNANDJOU</div>
                        </div>
                    </div> */}
                    <div class="row mt-5">
                        <div class="col-lg-6">
                            <h3 class="column-title">Who We Are</h3>
                            <p>Headquartered in Cotonou, Benin, <span style={{fontWeight: 'bold'}}>VAINQUEUR AGRO SARL</span> is a fast growing, leading agro-commodity trading company with vast experiences in the commodity value chain. We export mainly agro commodity from dry nuts, grains, seeds and dry fruits. Our core commodity are: Raw cashew nuts, cashew kernels, 
                                Shea nuts, Shea butter, Cocoa beans, Cocoa powder, non-gmo soya beans, Natural sesame seeds, Cotton seed/hulls, Yellow/White Maize, Dry/fresh ginger, Dry hibiscus flowers and semi husked coconut.
                            </p>
                            <blockquote><p>We are also open to new and innovative oppotunities/products for representation. We have contacts all other West African markets representation on win-win basis.</p></blockquote>
                        </div>

                        <div class="col-lg-6 mt-5 mt-lg-0">
                        
                        <div id="page-slider" class="page-slider small-bg">

                            <div class="item" style={{backgroundImage: `url(images/backraw.png)` }}>
                                <div class="container">
                                    <div class="box-slider-content">
                                    <div class="box-slider-text">
                                        <h2 class="box-slide-title">Leadership</h2>
                                    </div>    
                                    </div>
                                </div>
                            </div>

                            <div class="item" style={{backgroundImage: `url(images/backraw.png)` }}>
                                <div class="container">
                                    <div class="box-slider-content">
                                    <div class="box-slider-text">
                                        <h2 class="box-slide-title">Relationships</h2>
                                    </div>    
                                    </div>
                                </div>
                            </div>

                            <div class="item" style={{backgroundImage: `url(images/backsoya.png)` }}>
                                <div class="container">
                                    <div class="box-slider-content">
                                    <div class="box-slider-text">
                                        <h2 class="box-slide-title">Performance</h2>
                                    </div>    
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div class="row mt-5">
                        <div class="col-md-6">
                            <h3 class="column-title">Our Vision</h3>
                            <p>At VAS, we are enriching the lives of our employees, customers and communities. We want to make VAS a truly sustainable company. We are particularly attentive to the protection of the environment and the 
                                natural riches of the planet. We donate a portion of our earnings to support the less privileged in the rural areas where we often operate.
                            </p>
                            <h3 class="column-title mt-5">Our Philosophy</h3>
                            <p>You can make money without doing evil</p>
                        </div>
                        <div class="col-md-6">
                            <h3 class="column-title">Our Values</h3>
                            <div id="page-slider" class="page-slider small-bg">

                                <div class="item" style={{backgroundImage: `url(images/backsoya.png)` }}>
                                    <div class="container">
                                        <div class="box-slider-content">
                                        <div class="box-slider-text">
                                            <h2 class="box-slide-title">Integrity</h2>
                                        </div>    
                                        </div>
                                    </div>
                                </div>

                                <div class="item" style={{backgroundImage: `url(images/backraw.png)` }}>
                                    <div class="container">
                                        <div class="box-slider-content">
                                        <div class="box-slider-text">
                                            <h2 class="box-slide-title">Boldness</h2>
                                        </div>    
                                        </div>
                                    </div>
                                </div>

                                <div class="item" style={{backgroundImage: `url(images/backraw.png)` }}>
                                    <div class="container">
                                        <div class="box-slider-content">
                                        <div class="box-slider-text">
                                            <h2 class="box-slide-title">Honesty</h2>
                                        </div>    
                                        </div>
                                    </div>
                                </div>
                                <div class="item" style={{backgroundImage: `url(images/backsoya.png)` }}>
                                    <div class="container">
                                        <div class="box-slider-content">
                                        <div class="box-slider-text">
                                            <h2 class="box-slide-title">Trust</h2>
                                        </div>    
                                        </div>
                                    </div>
                                </div>
                                <div class="item" style={{backgroundImage: `url(images/backraw.png)` }}>
                                    <div class="container">
                                        <div class="box-slider-content">
                                        <div class="box-slider-text">
                                            <h2 class="box-slide-title">Accountability</h2>
                                        </div>    
                                        </div>
                                    </div>
                                </div>
                                <div class="item" style={{backgroundImage: `url(images/backraw.png)` }}>
                                    <div class="container">
                                        <div class="box-slider-content">
                                        <div class="box-slider-text">
                                            <h2 class="box-slide-title">Commitment to Costumers</h2>
                                        </div>    
                                        </div>
                                    </div>
                                </div>
                                <div class="item" style={{backgroundImage: `url(images/backsoya.png)` }}>
                                    <div class="container">
                                        <div class="box-slider-content">
                                        <div class="box-slider-text">
                                            <h2 class="box-slide-title">Passion</h2>
                                        </div>    
                                        </div>
                                    </div>
                                </div>
                                <div class="item" style={{backgroundImage: `url(images/backraw.png)` }}>
                                    <div class="container">
                                        <div class="box-slider-content">
                                        <div class="box-slider-text">
                                            <h2 class="box-slide-title">Fun</h2>
                                        </div>    
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
      </div>
    );
  }
}

export default About;
