import React, { Component } from "react";
import "./Header.css";
import { Fade } from "react-reveal";
import { NavLink, Link } from "react-router-dom";
import { greeting, settings } from "../../portfolio.js";
import SeoHeader from "../seoHeader/SeoHeader";

const onMouseEnter = (event, color) => {
  const el = event.target;
  el.style.backgroundColor = color;
};

const onMouseOut = (event) => {
  const el = event.target;
  el.style.backgroundColor = "transparent";
};

class Header extends Component {
  render() {
    const theme = this.props.theme;
    console.log(theme);
    const link = settings.isSplash ? "/splash" : "home";
    return (
        <div>
          <SeoHeader />
        <div id="top-bar" class="top-bar">
          <div class="container">
            <div class="row">
                <div class="col-lg-8 col-md-8">
                  <ul class="top-info text-center text-md-left">
                      <li><i class="fas fa-map-marker-alt"></i> <p class="info-text">Lot 589, M. FAFOLAHAN, Kpankpan Cotonou BENIN</p>
                      </li>
                  </ul>
                </div>
    
                <div class="col-lg-4 col-md-4 top-social text-center text-md-right">
                  <ul class="list-unstyled">
                      <li>
                        <a title="Facebook" href="https://web.facebook.com/vainqueuragrosarl">
                            <span class="social-icon"><i class="fab fa-facebook-f"></i></span>
                        </a>
                        <a title="Twitter" href="https://twitter.com/VainqueurAgro">
                            <span class="social-icon"><i class="fab fa-twitter"></i></span>
                        </a>
                        <a title="Instagram" href="https://www.instagram.com/vainqueuragrosarl">
                            <span class="social-icon"><i class="fab fa-instagram"></i></span>
                        </a>
                        {/* <a title="Linkdin" href="#">
                            <span class="social-icon"><i class="fab fa-github"></i></span>
                        </a> */}
                      </li>
                  </ul>
                </div>
            </div>
          </div>
        </div>
        <header id="header" class="header-one">
          <div class="bg-white">
            <div class="container">
              <div class="logo-area">
                  <div class="row align-items-center">
                    <div class="logo col-lg-3 text-center text-lg-left mb-3 mb-md-5 mb-lg-0">
                        <a class="d-block" href="/home">
                          <img loading="lazy" src="images/logoVAS.jpg" style={{width: '100px', height: '60px'}} alt="Vanqueur Agro"/>
                        </a>
                    </div>
          
                    <div class="col-lg-9 header-right">
                        <ul class="top-info-box">
                          <li>
                            <div class="info-box">
                              <div class="info-box-content">
                                  <p class="info-box-title">Call Us</p>
                                  <p class="info-box-subtitle"><a href="tel:(+9) 847-291-4353">(+229) 535 098 68</a></p>
                                  <p class="info-box-subtitle"><a href="tel:(+9) 847-291-4353">(+229) 638 652 99</a></p>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div class="info-box">
                              <div class="info-box-content">
                                  <p class="info-box-title">Email Us</p>
                                  <p class="info-box-subtitle"><a href="mailto:vainqueuragro@gmail.com">vainqueuragro@gmail.com</a></p>
                                  <p class="info-box-subtitle"><a href="mailto:vainqueuragro@gmail.com">info@vainqueur-agro.com</a></p>
                              </div>
                            </div>
                          </li>
                          {/* <li class="last">
                            <div class="info-box last">
                              <div class="info-box-content">
                                  <p class="info-box-title">Global Certificate</p>
                                  <p class="info-box-subtitle">ISO 9001:2017</p>
                              </div>
                            </div>
                          </li> */}
                          <li class="header-get-a-quote">
                                <a
                                  href="/contact"
                                  tag={Link}
                                  class="btn btn-primary"
                                >
                                  Contact Us
                                </a>
                          </li>
                        </ul>
                    </div>
                  </div>
          
              </div>
            </div>
          </div>

          <div class="site-navigation">
            <div class="container">
              <div class="row">
                  <div class="col-lg-12">
                      <nav class="navbar navbar-expand-lg navbar-dark p-0">
                        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target=".navbar-collapse" aria-controls="navbar-collapse" aria-expanded="false" aria-label="Toggle navigation">
                            <span class="navbar-toggler-icon"></span>
                        </button>
                        
                        <div id="navbar-collapse" class="collapse navbar-collapse">
                            <ul class="nav navbar-nav mr-auto">
                              <li class="nav-item"><a class="nav-link" href="/">Home</a></li>
                              
                              <li class="nav-item"><a
                                  href="/about"
                                  tag={Link}
                                  class="nav-link"
                                >
                                  About
                                </a>
                                </li>
                              {/* <li class="nav-item"><a
                                  href="#"
                                  tag={Link}
                                  class="nav-link"
                                >
                                  Services
                                </a></li> */}
                      
                              {/* <li class="nav-item dropdown">
                                  <a href="#" class="nav-link dropdown-toggle" data-toggle="dropdown">Products <i class="fa fa-angle-down"></i></a>
                                  <ul class="dropdown-menu" role="menu">
                                    <li><a href="/products">Products All</a></li>
                                    <li><a href="/products/Raw Cashew Nuts">Raw Cashew Nuts</a></li>
                                    <li><a href="/products/Coton Seeds">Coton Seeds</a></li>
                                    <li><a href="/products/Non-gmo Soya Beans">Non-gmo Soya Beans</a></li>
                                  </ul>
                              </li> */}
                              <li class="nav-item">
                                <a
                                  href="/products"
                                  tag={Link}
                                  class="nav-link"
                                >
                                  Products
                                </a></li>
                              <li class="nav-item">
                                <a
                                  href="/contact"
                                  tag={Link}
                                  class="nav-link"
                                >
                                  Contact
                                </a></li>
                            </ul>
                        </div>
                      </nav>
                  </div>
                </div>

                <div class="nav-search">
                  <span id="search"><i class="fa fa-search"></i></span>
                </div>

                <div class="search-block" style={{display: 'none'}}>
                  <label for="search-field" class="w-100 mb-0">
                    <input type="text" class="form-control" id="search-field" placeholder="Type what you want and enter" />
                  </label>
                  <span class="search-close">&times;</span>
                </div>

            </div>
          </div>
        </header>
          {/* <header className="header">
            <NavLink to={link} tag={Link} className="logo">
              <span style={{ color: theme.text }}> &lt;</span>
              <span className="logo-name" style={{ color: theme.text }}>
                {greeting.logo_name}
              </span>
              <span style={{ color: theme.text }}>/&gt;</span>
            </NavLink>
            <input className="menu-btn" type="checkbox" id="menu-btn" />
            <label className="menu-icon" htmlFor="menu-btn">
              <span className="navicon"></span>
            </label>
            <ul className="menu" style={{ backgroundColor: theme.body }}>
              <li>
                <NavLink
                  to="/home"
                  tag={Link}
                  activeStyle={{ fontWeight: "bold" }}
                  style={{ color: theme.text }}
                  onMouseEnter={(event) => onMouseEnter(event, theme.highlight)}
                  onMouseOut={(event) => onMouseOut(event)}
                >
                  Home
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/education"
                  tag={Link}
                  activeStyle={{ fontWeight: "bold" }}
                  style={{ color: theme.text }}
                  onMouseEnter={(event) => onMouseEnter(event, theme.highlight)}
                  onMouseOut={(event) => onMouseOut(event)}
                >
                  Education
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/experience"
                  tag={Link}
                  activeStyle={{ fontWeight: "bold" }}
                  style={{ color: theme.text }}
                  onMouseEnter={(event) => onMouseEnter(event, theme.highlight)}
                  onMouseOut={(event) => onMouseOut(event)}
                >
                  Experience
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/projects"
                  tag={Link}
                  activeStyle={{ fontWeight: "bold" }}
                  style={{ color: theme.text }}
                  onMouseEnter={(event) => onMouseEnter(event, theme.highlight)}
                  onMouseOut={(event) => onMouseOut(event)}
                >
                  Projects
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/opensource"
                  tag={Link}
                  activeStyle={{ fontWeight: "bold" }}
                  style={{ color: theme.text }}
                  onMouseEnter={(event) => onMouseEnter(event, theme.highlight)}
                  onMouseOut={(event) => onMouseOut(event)}
                >
                  Open Source
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/contact"
                  tag={Link}
                  activeStyle={{ fontWeight: "bold" }}
                  style={{ color: theme.text }}
                  onMouseEnter={(event) => onMouseEnter(event, theme.highlight)}
                  onMouseOut={(event) => onMouseOut(event)}
                >
                  Contact Me
                </NavLink>
              </li>
            </ul>
          </header> */}
        </div>
    );
  }
}
export default Header;
