import React, { Component } from "react";
import BannerArea from "../../components/banner/BannerArea";
import { products } from "../../portfolio";

class Product extends Component {
  state = {
    productName: "Raw Cashew Nuts",
    loadPage: true
  }

  constructor(props) {
    super(props);

    this.setProductName = this.setProductName.bind(this);
    this.loadPage = this.setLoadPage.bind(this);

    let productName = props.productName;
    let loadPage = props.loadPage;

    this.state = {
      productName: productName,
      loadPage: loadPage
    };
  }

  setProductName(p) {
    this.setState({productName: p});
  }

  setLoadPage(load) {
    this.setState({loadPage: load});
  }

  componentDidMount = () => {
    // this.reload();
  }

  // componentDidUpdate = () => {
  //   this.reload();
  // }

reload() {
  if(this.state.loadPage == undefined) {
    window.location.reload();
  }
  this.setLoadPage(false);
}

  render() {
    const theme = this.props.theme;
    return (
      <div>
        <BannerArea theme={this.props.theme} page={"Our Products"}/>
        <section id="main-container" class="main-container">
  <div class="container">
    <div class="row">

      <div class="col-xl-3 col-lg-4">
        <div class="sidebar sidebar-left">
          <div class="widget">
            <h3 class="widget-title">Our Products</h3>
            <ul class="nav service-menu">
              <li class={this.state.productName == "Raw Cashew Nuts" || this.state.productName == undefined ? `active` : ``}><a onClick={() => this.setProductName("Raw Cashew Nuts")}>Raw Cashew Nuts</a></li>
              {/* <li class={this.state.productName == "Hardwood Charcoal" ? `active` : ``}><a onClick={() => this.setProductName("Hardwood Charcoal")}>Hardwood Charcoal</a></li> */}
              {/* <li class={this.state.productName == "Semi husked Coconut" ? `active` : ``}><a onClick={() => this.setProductName("Semi husked Coconut")}>Semi husked Coconut</a></li> */}
              <li class={this.state.productName == "Yellow Maize" ? `active` : ``}><a onClick={() => this.setProductName("Yellow Maize")}>Yellow Maize</a></li>
              <li class={this.state.productName == "Natural Sesame Seeds" ? `active` : ``}><a onClick={() => this.setProductName("Natural Sesame Seeds")}>Natural Sesame Seeds</a></li>
              {/* <li class={this.state.productName == "Hulled Sesame" ? `active` : ``}><a onClick={() => this.setProductName("Hulled Sesame")}>Hulled Sesame</a></li> */}
              <li class={this.state.productName == "Dry Ginger" ? `active` : ``}><a onClick={() => this.setProductName("Dry Ginger")}>Dry Ginger</a></li>
              {/* <li class={this.state.productName == "Shea Nuts" ? `active` : ``}><a onClick={() => this.setProductName("Shea Nuts")}>Shea Nuts</a></li> */}
              {/* <li class={this.state.productName == "Peanuts" ? `active` : ``}><a onClick={() => this.setProductName("Peanuts")}>Peanuts</a></li> */}
              <li class={this.state.productName == "Non-Gmo Soybeans" ? `active` : ``}><a onClick={() => this.setProductName("Non-Gmo Soybeans")}>Non-Gmo Soybeans</a></li>
              {/* <li class={this.state.productName == "Cotton Seeds" ? `active` : ``}><a onClick={() => this.setProductName("Cotton Seeds")}>Cotton Seeds</a></li> */}
              <li class={this.state.productName == "Dried Hibiscus Flowers" ? `active` : ``}><a onClick={() => this.setProductName("Dried Hibiscus Flowers")}>Dried Hibiscus Flowers</a></li>
            </ul>
          </div>

          {/* <div class="widget">
            <div class="quote-item quote-border">
              <div class="quote-text-border">
                Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid.
              </div>

              <div class="quote-item-footer">
                <img loading="lazy" class="testimonial-thumb" src="images/clients/testimonial1.png" alt="testimonial"/>
                <div class="quote-item-info">
                  <h3 class="quote-author">Weldon Cash</h3>
                  <span class="quote-subtext">CEO, First Choice Group</span>
                </div>
              </div>
            </div>
          </div> */}

        </div>
      </div>

      <div class="col-xl-8 col-lg-8">
      {
        products.data.filter(prod => prod.name == (this.state.productName == undefined ? "Raw Cashew Nuts" : this.state.productName)).map((product) => {
          return (
            <div class="content-inner-page">

              <h2 class="column-title mrt-0">{product.name}</h2>

              <div class="gap-40"></div>

              <div id="page-slider" class="page-slider">
                {
                  product.images.length > 0 && product.images.map((img) =>{
                    return <div class="item" style={{height: "400px"}}>
                    <img loading="lazy" class="img-fluid" src={img} alt={product.name} />
                  </div>;
                  })
                }
                {/* <div class="item">
                  <img loading="lazy" class="img-fluid" src="images/projects/project1.jpg" alt="project-slider-image" />
                </div>

                <div class="item">
                  <img loading="lazy" class="img-fluid" src="images/projects/project2.jpg" alt="project-slider-image" />
                </div> */}
              </div>

              <div class="gap-40"></div>

              <div class="row">
                

                <div class="col-md-12 mt-5 mt-md-0">

                  <div class="accordion accordion-group accordion-classic" id="construction-accordion">
                    <div class="card">
                      <div class="card-header p-0 bg-transparent" id="headingOne">
                        <h2 class="mb-0">
                          <button class="btn btn-block text-left" type="button" data-toggle="collapse"
                            data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            Characteristics
                          </button>
                        </h2>
                      </div>

                      {
                        this.state.productName == "Raw Cashew Nuts" || this.state.productName == undefined ? 
                        <div id="collapseOne" class="collapse show" aria-labelledby="headingOne"
                        data-parent="#construction-accordion">
                        
                        <div class="card-body">
                          <span style={{fontWeight: 'bold'}}>Crop</span>: {product.crop}
                        </div>
                        <div class="card-body">
                          <span style={{fontWeight: 'bold'}}>Origin</span>: {product.origin}
                        </div>
                        <div class="card-body">
                          <span style={{fontWeight: 'bold'}}>Out Turn</span>: {product.out}
                        </div>
                        <div class="card-body">
                          <span style={{fontWeight: 'bold'}}>Nut Count</span>: {product.nutCount}
                        </div>
                        <div class="card-body">
                          <span style={{fontWeight: 'bold'}}>Moisture</span>: {product.moisture}
                        </div>
                        <div class="card-body">
                          <span style={{fontWeight: 'bold'}}>Defective</span>: {product.defective}
                        </div>
                        <div class="card-body">
                          <span style={{fontWeight: 'bold'}}>Packing</span>: {product.packing}
                        </div>
                      </div> : this.state.productName == "Hardwood Charcoal" ? <div id="collapseOne" class="collapse show" aria-labelledby="headingOne"
                        data-parent="#construction-accordion">
                        
                        <div class="card-body">
                          <span style={{fontWeight: 'bold'}}>Origin</span>: {product.origin}
                        </div>
                        <div class="card-body">
                          <span style={{fontWeight: 'bold'}}>Moisture</span>: {product.moisture}
                        </div>
                        <div class="card-body">
                          <span style={{fontWeight: 'bold'}}>Fixed Carbon</span>: {product.fixedCarbon}
                        </div>
                        <div class="card-body">
                          <span style={{fontWeight: 'bold'}}>Caloric Value</span>: {product.caloric}
                        </div>
                        <div class="card-body">
                          <span style={{fontWeight: 'bold'}}>Ash Content</span>: {product.ash}
                        </div>
                        <div class="card-body">
                          <span style={{fontWeight: 'bold'}}>Volatile Matter</span>: {product.volatile}
                        </div>
                        <div class="card-body">
                          <span style={{fontWeight: 'bold'}}>Grades</span>: {product.grades}
                        </div>
                        <div class="card-body">
                          <span style={{fontWeight: 'bold'}}>Shape</span>: {product.shape}
                        </div>
                        <div class="card-body">
                          <span style={{fontWeight: 'bold'}}>Foreign Matters</span>: {product.foreign}
                        </div>
                        <div class="card-body">
                          <span style={{fontWeight: 'bold'}}>Packing</span>: {product.packing}
                        </div>
                      </div> : this.state.productName == "Dried Hibiscus Flowers" ? <div id="collapseOne" class="collapse show" aria-labelledby="headingOne"
                        data-parent="#construction-accordion">
                        
                        <div class="card-body">
                          <span style={{fontWeight: 'bold'}}>Origin</span>: {product.origin}
                        </div>
                        <div class="card-body">
                          <span style={{fontWeight: 'bold'}}>Crop</span>: {product.crop}
                        </div>
                        <div class="card-body">
                          <span style={{fontWeight: 'bold'}}>Drying Process</span>: {product.dryingProc}
                        </div>
                        <div class="card-body">
                          <span style={{fontWeight: 'bold'}}>Drying Color</span>: {product.dryingCol}
                        </div>
                        <div class="card-body">
                          <span style={{fontWeight: 'bold'}}>Percentage of Brocken</span>: {product.percentBrok}
                        </div>
                        <div class="card-body">
                          <span style={{fontWeight: 'bold'}}>Moisture</span>: {product.moisture}
                        </div>
                        <div class="card-body">
                          <span style={{fontWeight: 'bold'}}>Foreign Matter</span>: {product.foreign}
                        </div>
                        <div class="card-body">
                          <span style={{fontWeight: 'bold'}}>Insect Infestation</span>: {product.insect}
                        </div>
                        <div class="card-body">
                          <span style={{fontWeight: 'bold'}}>Weed Seeds</span>: {product.weedSeed}
                        </div>
                        <div class="card-body">
                          <span style={{fontWeight: 'bold'}}>Packing</span>: {product.packing}
                        </div>
                      </div> : this.state.productName == "Dry Ginger" ? <div id="collapseOne" class="collapse show" aria-labelledby="headingOne"
                        data-parent="#construction-accordion">
                        
                        <div class="card-body">
                          <span style={{fontWeight: 'bold'}}>Origin</span>: {product.origin}
                        </div>
                        <div class="card-body">
                          <span style={{fontWeight: 'bold'}}>Crop</span>: {product.crop}
                        </div>
                        <div class="card-body">
                          <span style={{fontWeight: 'bold'}}>Admixture</span>: {product.admixture}
                        </div>
                        <div class="card-body">
                          <span style={{fontWeight: 'bold'}}>Total Oil</span>: {product.totalOil}
                        </div>
                        <div class="card-body">
                          <span style={{fontWeight: 'bold'}}>Fat</span>: {product.fat}
                        </div>
                        <div class="card-body">
                          <span style={{fontWeight: 'bold'}}>Ash</span>: {product.ash}
                        </div>
                        <div class="card-body">
                          <span style={{fontWeight: 'bold'}}>Thickness</span>: {product.thickness}
                        </div>
                        <div class="card-body">
                          <span style={{fontWeight: 'bold'}}>Mould</span>: {product.mould}
                        </div>
                        <div class="card-body">
                          <span style={{fontWeight: 'bold'}}>Moisture</span>: {product.moisture}
                        </div>
                        <div class="card-body">
                          <span style={{fontWeight: 'bold'}}>Acide Ins Ash</span>: {product.acid}
                        </div>
                        <div class="card-body">
                          <span style={{fontWeight: 'bold'}}>Alcohol Soluble Extract</span>: {product.alcohol}
                        </div>
                        <div class="card-body">
                          <span style={{fontWeight: 'bold'}}>Aflatoxin</span>: {product.aflatoxin}
                        </div>
                        <div class="card-body">
                          <span style={{fontWeight: 'bold'}}>Salmonella</span>: {product.salmonella}
                        </div>
                        <div class="card-body">
                          <span style={{fontWeight: 'bold'}}>Packing</span>: {product.packing}
                        </div>
                      </div> : this.state.productName == "Natural Sesame Seeds" ? <div id="collapseOne" class="collapse show" aria-labelledby="headingOne"
                        data-parent="#construction-accordion">
                        
                        <div class="card-body">
                          <span style={{fontWeight: 'bold'}}>Crop</span>: {product.crop}
                        </div>
                        <div class="card-body">
                          <span style={{fontWeight: 'bold'}}>Origin</span>: {product.origin}
                        </div>
                        <div class="card-body">
                          <span style={{fontWeight: 'bold'}}>Purity</span>: {product.purity}
                        </div>
                        <div class="card-body">
                          <span style={{fontWeight: 'bold'}}>Color</span>: {product.color}
                        </div>
                        <div class="card-body">
                          <span style={{fontWeight: 'bold'}}>Moisture</span>: {product.moisture}
                        </div>
                        <div class="card-body">
                          <span style={{fontWeight: 'bold'}}>Oil Content</span>: {product.oilContent}
                        </div>
                        <div class="card-body">
                          <span style={{fontWeight: 'bold'}}>Packing</span>: {product.packing}
                        </div>
                      </div> : this.state.productName == "Non-Gmo Soybeans" ? <div id="collapseOne" class="collapse show" aria-labelledby="headingOne"
                        data-parent="#construction-accordion">
                        
                        <div class="card-body">
                          <span style={{fontWeight: 'bold'}}>Crop</span>: {product.crop}
                        </div>
                        <div class="card-body">
                          <span style={{fontWeight: 'bold'}}>Origin</span>: {product.origin}
                        </div>
                        <div class="card-body">
                          <span style={{fontWeight: 'bold'}}>Moisture</span>: {product.moisture}
                        </div>
                        <div class="card-body">
                          <span style={{fontWeight: 'bold'}}>Fiber</span>: {product.fiber}
                        </div>
                        <div class="card-body">
                          <span style={{fontWeight: 'bold'}}>Oil</span>: {product.oil}
                        </div>
                        <div class="card-body">
                          <span style={{fontWeight: 'bold'}}>Fat</span>: {product.fat}
                        </div>
                        <div class="card-body">
                          <span style={{fontWeight: 'bold'}}>Total Damage</span>: {product.totalDamage}
                        </div>
                        <div class="card-body">
                          <span style={{fontWeight: 'bold'}}>Heat Damage</span>: {product.heatDamage}
                        </div>
                        <div class="card-body">
                          <span style={{fontWeight: 'bold'}}>Protein</span>: {product.protein}
                        </div>
                        <div class="card-body">
                          <span style={{fontWeight: 'bold'}}>Splits</span>: {product.splits}
                        </div>
                        <div class="card-body">
                          <span style={{fontWeight: 'bold'}}>Purity</span>: {product.purity}
                        </div>
                      </div> 
                      : this.state.productName == "Yellow Maize" ? <div id="collapseOne" class="collapse show" aria-labelledby="headingOne"
                        data-parent="#construction-accordion">
                        
                        <div class="card-body">
                          <span style={{fontWeight: 'bold'}}>Origin</span>: {product.origin}
                        </div>
                        <div class="card-body">
                          <span style={{fontWeight: 'bold'}}>Crop</span>: {product.crop}
                        </div>
                        <div class="card-body">
                          <span style={{fontWeight: 'bold'}}>Protein</span>: {product.protein}
                        </div>
                        <div class="card-body">
                          <span style={{fontWeight: 'bold'}}>Moisture</span>: {product.moisture}
                        </div>
                        <div class="card-body">
                          <span style={{fontWeight: 'bold'}}>Admixture</span>: {product.admixture}
                        </div>
                        <div class="card-body">
                          <span style={{fontWeight: 'bold'}}>Broken Kernels</span>: {product.broken}
                        </div>
                        <div class="card-body">
                          <span style={{fontWeight: 'bold'}}>Radiation</span>: {product.radiation}
                        </div>
                        <div class="card-body">
                          <span style={{fontWeight: 'bold'}}>Aflatoxin</span>: {product.aflatoxin}
                        </div>
                        <div class="card-body">
                          <span style={{fontWeight: 'bold'}}>Damaged/Discoloured</span>: {product.damaged}
                        </div>
                      </div>
                       : this.state.productName == "Cotton Seeds" ? <div id="collapseOne" class="collapse show" aria-labelledby="headingOne"
                        data-parent="#construction-accordion">
                        
                        <div class="card-body">
                          <span style={{fontWeight: 'bold'}}>Origin</span>: {product.origin}
                        </div>
                        <div class="card-body">
                          <span style={{fontWeight: 'bold'}}>Humidity</span>: {product.humidity}
                        </div>
                        <div class="card-body">
                          <span style={{fontWeight: 'bold'}}>Moisture</span>: {product.moisture}
                        </div>
                        <div class="card-body">
                          <span style={{fontWeight: 'bold'}}>Mineral</span>: {product.mineral}
                        </div>
                        <div class="card-body">
                          <span style={{fontWeight: 'bold'}}>Cellulose</span>: {product.cellulose}
                        </div>
                        <div class="card-body">
                          <span style={{fontWeight: 'bold'}}>Protein</span>: {product.protein}
                        </div>
                        <div class="card-body">
                          <span style={{fontWeight: 'bold'}}>Oil Content</span>: {product.oil}
                        </div>
                        <div class="card-body">
                          <span style={{fontWeight: 'bold'}}>Purity</span>: {product.purity}
                        </div>
                      </div> : <div></div>
                      }
                      
                    </div>
                    {/* <div class="card">
                      <div class="card-header p-0 bg-transparent" id="headingTwo">
                        <h2 class="mb-0">
                          <button class="btn btn-block text-left collapsed" type="button" data-toggle="collapse"
                            data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                            What are the first aid requirements for sites?
                          </button>
                        </h2>
                      </div>
                      <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo"
                        data-parent="#construction-accordion">
                        <div class="card-body">
                          Anemi nim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea com modo
                          consequat.
                          Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                          pariatur.
                          Excepteur sint occaecat cupid henderit in voluptate velit esse cillu oris nisi ut aliquip ex ea
                          com
                          matat.
                        </div>
                      </div>
                    </div>
                    <div class="card">
                      <div class="card-header p-0 bg-transparent" id="headingThree">
                        <h2 class="mb-0">
                          <button class="btn btn-block text-left collapsed" type="button" data-toggle="collapse"
                            data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                            What is an appointed person?
                          </button>
                        </h2>
                      </div>
                      <div id="collapseThree" class="collapse" aria-labelledby="headingThree"
                        data-parent="#construction-accordion">
                        <div class="card-body">
                          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been
                          the
                          industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type
                          and
                          scrambled it to make a type specimen book.
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
              
              <div class="gap-40"></div>

              <div class="call-to-action classic">
                <div class="row align-items-center">
                  <div class="col-md-8 text-center text-md-left">
                    <div class="call-to-action-text">
                      <h3 class="action-title">Interested with this product.</h3>
                    </div>
                  </div>
                  <div class="col-md-4 text-center text-md-right mt-3 mt-md-0">
                    <div class="call-to-action-btn">
                      <a class="btn btn-primary" href="/contact">Contact Us</a>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          );
          
        })
      }
        
      </div>

    </div>
  </div>
</section>
      </div>
    );
  }
}

export default Product;
