import React from "react";
import "./Greeting.css";
// import SocialMedia from "../../components/socialMedia/SocialMedia";

export default function Greeting(props) {
  const theme = props.theme;
  return (
      <div class="banner-carousel banner-carousel-1 mb-0">
        <div class="banner-carousel-item" style={{backgroundImage: `url(images/backraw.png)` }}>
          <div class="slider-content">
              <div class="container h-100">
                <div class="row align-items-center h-100">
                    <div class="col-md-12 text-center">
                      <h2 class="slide-title" data-animation-in="slideInLeft">Vainqueur Agro SARL</h2>
                      <h3 class="slide-sub-title" data-animation-in="slideInRight">Creating better value</h3>
                      {/* <p class="slider-description lead" data-animation-in="slideInRight">Your Choice is Simple.</p> */}
                      {/* <p data-animation-in="slideInLeft" data-duration-in="1.2">
                          <a href="/services" class="slider btn btn-primary">Our Services</a>
                          <a href="/contact" class="slider btn btn-primary border">Contact Now</a>
                      </p> */}
                    </div>
                </div>
              </div>
          </div>
        </div>

        <div class="banner-carousel-item" style={{backgroundImage: `url(images/backraw.png)` }}>
          <div class="slider-content text-left">
              <div class="container h-100">
                <div class="row align-items-center h-100">
                    <div class="col-md-12">
                      <h2 class="slide-title-box" data-animation-in="slideInDown">Vainqueur Agro SARL</h2>
                      <h3 class="slide-sub-title" data-animation-in="fadeIn">cashew nut</h3>
                      {/* <h3 class="slide-title" data-animation-in="slideInLeft">We sell high quality cashew nut.</h3>
                      <p data-animation-in="slideInRight">
                          <a href="/products" class="slider btn btn-primary border">Our Products</a>
                      </p> */}
                    </div>
                </div>
              </div>
          </div>
        </div>

        <div class="banner-carousel-item" style={{backgroundImage: `url(images/backsoya.png)` }}>
          <div class="slider-content text-right">
              <div class="container h-100">
                <div class="row align-items-center h-100">
                    <div class="col-md-12">
                      <h2 class="slide-title" data-animation-in="slideInDown">Vainqueur Agro SARL</h2>
                      <h3 class="slide-sub-title" data-animation-in="fadeIn">Non-Gmo Soybeans</h3>
                      {/* <p class="slider-description lead" data-animation-in="slideInRight">We sell high quality products from the raw material.</p>
                      <div data-animation-in="slideInLeft">
                          <a href="/contact" class="slider btn btn-primary" aria-label="contact-with-us">Get Free Quote</a>
                          <a href="/about" class="slider btn btn-primary border" aria-label="learn-more-about-us">Learn more</a>
                      </div> */}
                    </div>
                </div>
              </div>
          </div>
        </div>
      </div>
  );
}
