import React, { Component } from "react";
import "./ContactComponent.css";
import BannerArea from "../../components/banner/BannerArea";
import axios from "axios";

class Contact extends Component {
  state = {
  model: {
    name: "",
    email: "",
    subject: "",
    message: ""
  },
  name: "",
  email: "",
  subject: "",
  message: "",
  loading: false,
  submit: false
}

  constructor(props) {
    super(props);

    this.setName = this.setName.bind(this);
    this.setEmail = this.setEmail.bind(this);
    this.setSubject = this.setSubject.bind(this);
    this.setMessage = this.setMessage.bind(this);

    if (props.current) {
      let name = props.current.name;
    let email = props.current.email;
    let subject = props.current.subject;
    let message = props.current.message;

    let model = {
      name: props.current.name,
      email: props.current.email,
      subject: props.current.subject,
      message: props.current.message,
  };

    this.state = {
      model: model,
      name: name,
      email: email,
      subject: subject,
      message: message,
      loading: false,
      submit: false,
    };
    }
  }

  setName(e) {
    const keyValue = { name: e.target.value };
    this.setState({ model: { ...this.state.model, ...keyValue }});
  }

  setEmail(e) {
    const keyValue = { email: e.target.value };
    this.setState({ model: { ...this.state.model, ...keyValue }});
  }

  setSubject(e) {
    const keyValue = { subject: e.target.value };
    this.setState({ model: { ...this.state.model, ...keyValue }});
  }

  setMessage(e) {
    const keyValue = { message: e.target.value };
    this.setState({ model: { ...this.state.model, ...keyValue }});
  }

  // componentDidMount = () => {
  //   axios.get("http://localhost:3001/read").then((response)=>{
  //   console.log(response.data)
  // })
  // }

  // componentDidUpdate = () => {
  //   if (this.state.message != "") {
  //   this.setState({ submit: true }); 
  //   }
  // }

  sendMessage() {
    // if (this.state.message != "") {
      alert("enter")
      axios.post("http://localhost:3001/mail", this.state.model).then((response)=>{
        if (response.data.msg === 'success'){
          console.log("Email sent, awesome!"); 
          this.setState({ loading: false });
        }else if(response.data.msg === 'fail'){
          console.log("Oops, something went wrong. Try again")
          this.setState({ loading: false });
        }
      })
    // } 
      
  }

  render() {
    return (
      <div>
        <BannerArea theme={this.props.theme} page={"Contact Us"}/>
        <div>
          <section id="main-container" class="main-container">
            <div class="container">

              <div class="row text-center">
                <div class="col-12">
                  <h2 class="section-title">Reaching our Office</h2>
                  <h3 class="section-sub-title">Find Our Location</h3>
                </div>
              </div>

              <div class="row">
                <div class="col-md-4">
                  <div class="ts-service-box-bg text-center h-100">
                    <span class="ts-service-icon icon-round">
                      <i class="fas fa-map-marker-alt mr-0"></i>
                    </span>
                    <div class="ts-service-box-content">
                      <h4>Visit Our Office</h4>
                      <p>Lot 589, M. FAFOLAHAN, Kpankpan Cotonou BENIN</p>
                    </div>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="ts-service-box-bg text-center h-100">
                    <span class="ts-service-icon icon-round">
                      <i class="fa fa-envelope mr-0"></i>
                    </span>
                    <div class="ts-service-box-content">
                      <h4>Email Us</h4>
                      <p>vainqueuragro@gmail.com</p>
                    </div>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="ts-service-box-bg text-center h-100">
                    <span class="ts-service-icon icon-round">
                      <i class="fa fa-phone-square mr-0"></i>
                    </span>
                    <div class="ts-service-box-content">
                      <h4>Call Us</h4>
                      <p>(+229) 535 098 68, (+229) 638 652 99</p>
                    </div>
                  </div>
                </div>

              </div>

              <div class="gap-60"></div>

              {/* <div class="google-map">
                <div id="map" class="map" data-latitude="40.712776" data-longitude="-74.005974" data-marker="images/marker.png" data-marker-name="Constra"></div>
              </div> */}

              <div class="gap-40"></div>

              <div class="row">
                <div class="col-md-12">
                  <h3 class="column-title">We love to hear</h3>
                  {/* <form id="contact-form" role="form"> */}
                  <div id="contact-form">
                    <div class="error-container"></div>
                    <div class="row">
                      <div class="col-md-4">
                        <div class="form-group">
                          <label>Name</label>
                          <input class="form-control form-control-name" name="name" id="name" placeholder="" type="text" onChange={(e)=>{this.setName(e)}} required />
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group">
                          <label>Email</label>
                          <input class="form-control form-control-email" onChange={(e)=>{this.setEmail(e)}} name="email" id="email" placeholder="" type="email"
                            required />
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group">
                          <label>Subject</label>
                          <input class="form-control form-control-subject" onChange={(e)=>{this.setSubject(e)}} name="subject" id="subject" placeholder="" required />
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <label>Message</label>
                      <textarea class="form-control form-control-message" onChange={(e)=>{this.setMessage(e)}} name="message" id="message" placeholder="" rows="10"
                        required></textarea>
                    </div>
                    <div class="text-right"><br/>
                      {/* <button class="btn btn-primary solid blank" type="submit">Send Message</button> */}
                      <button class="btn btn-primary solid blank" onClick={this.sendMessage()} type="submit">Send Message</button>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default Contact;
