import React, { useEffect, useState } from "react";
import BannerArea from "../../components/banner/BannerArea";
import axios from "axios";
import classnames from "classnames";

export default function Contact2({theme}) {

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [sended, setSended] = useState(null);

  useEffect(() => {

  }, []);

  const sendMessage = () => {
    setLoading(true);
      axios.post("https://www.vainqueur-agro.com/api/contact.php", {email: email, name: name, subject: subject, message: message}).then((response)=>{
      console.log(response.status);  
      if (response.status === 200){
          console.log("Email sent, awesome!");
          setLoading(false);
          setSended(true);
          initializeVariables();
        }else if(response.data.msg === 'fail'){
          console.log("Oops, something went wrong. Try again")
          setLoading(false);
          setSended(false);
        }
      })
      
  }

  function initializeVariables() {
      setEmail("");
      setName("");
      setSubject("");
      setMessage("");
  }

  return (
    <div>
        <BannerArea theme={theme} page={"Contact Us"}/>
        <div>
          <section id="main-container" class="main-container">
            <div class="container">

              <div class="row text-center">
                <div class="col-12">
                  <h2 class="section-title">Reaching our Office</h2>
                  <h3 class="section-sub-title">Find Our Location</h3>
                </div>
              </div>

              <div class="row">
                <div class="col-md-4">
                  <div class="ts-service-box-bg text-center h-100">
                    <span class="ts-service-icon icon-round">
                      <i class="fas fa-map-marker-alt mr-0"></i>
                    </span>
                    <div class="ts-service-box-content">
                      <h4>Visit Our Office</h4>
                      <p>Lot 589, M. FAFOLAHAN, Kpankpan Cotonou BENIN</p>
                    </div>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="ts-service-box-bg text-center h-100">
                    <span class="ts-service-icon icon-round">
                      <i class="fa fa-envelope mr-0"></i>
                    </span>
                    <div class="ts-service-box-content">
                      <h4>Email Us</h4>
                      <p>vainqueuragro@gmail.com</p>
                      <p>info@vainqueur-agro.com</p>
                    </div>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="ts-service-box-bg text-center h-100">
                    <span class="ts-service-icon icon-round">
                      <i class="fa fa-phone-square mr-0"></i>
                    </span>
                    <div class="ts-service-box-content">
                      <h4>Call Us</h4>
                      <p>(+229) 535 098 68</p>
                      <p>(+229) 638 652 99</p>
                    </div>
                  </div>
                </div>

              </div>

              <div class="gap-60"></div>

              {/* <div class="google-map">
                <div id="map" class="map" data-latitude="40.712776" data-longitude="-74.005974" data-marker="images/marker.png" data-marker-name="Constra"></div>
              </div> */}

              <div class="gap-40"></div>

              <div class="row">
                <div class="col-md-12">
                  <h3 class="column-title">We love to hear</h3>
                  {/* <form id="contact-form" onSubmit={sendMessage} role="form"> */}
                  <div id="contact-form">
                    <div class={sended==true ? 'succes-container' : sended==false ? 'error-container' : ''}>{sended == true ? 'Message sent successfully !' : sended == false ? 'Error sending, please try again' : ''}</div>
                    <div class="row">
                      <div class="col-md-4">
                        <div class="form-group">
                          <label>Name</label>
                          <input class="form-control form-control-name" name="name" id="name" value={name} type="text" onChange={(e)=>{setName(e.target.value)}} required />
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group">
                          <label>Email</label>
                          <input class="form-control form-control-email" onChange={(e)=>{setEmail(e.target.value)}} name="email" id="email" value={email} type="email"
                            required />
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group">
                          <label>Subject</label>
                          <input class="form-control form-control-subject" onChange={(e)=>{setSubject(e.target.value)}} name="subject" id="subject" value={subject} type="text" required />
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <label>Message</label>
                      <textarea class="form-control form-control-message" onChange={(e)=>{setMessage(e.target.value)}} name="message" id="message" value={message} rows="10"
                        required></textarea>
                    </div>
                    <div class="text-right"><br/>
                      {/* <button class="btn btn-primary solid blank" type="submit">Send Message</button> */}
                      <button class="btn btn-primary solid blank align-center" onClick={sendMessage} type="submit">
                          <span className={classnames({ 'd-none': loading })}>Send Message</span>
                          <i className={classnames('fa fa-circle-o-notch', { 'fa-spin': loading, 'd-none': !loading })} style={{width: "100px"}}></i>
                        </button>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </section>
        </div>
      </div>
  );
};
